<template>
  <v-app >
    <Navigation/>
    <v-main class="home">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div class="content" transition="scroll-y-reverse-transition" :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Locaciones
            </h1>
          </div>
          <hr class="content__divider"/>

          <div class="service row">
            <v-row>
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2"
                v-for="item in venues"
                :key="item.id">
                  <ServiceItem
                    :title="item.attributes.title"
                    :description="item.attributes.description"
                    :date="item.attributes.date"
                    :img="item.attributes.image != undefined ? item.attributes.image.thumb.url : '//via.placeholder.com/300x200'"
                    :theme="theme"
                    :service_id="item.id.toString()"
                    button_text="locación"
                  />
              </div>
            </v-row>
          </div>

        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Logo from '../assets/img/logo.png'

  export default {
    name: 'Benefits',
    data () {
      return {
        venues:[],
        actual_page: 1,
        Logo: Logo,
        theme:{
          primaryColor: '#51C97B',
          secundaryColor: 'rgb(130 215 160)',
          service: 'locations'
        },
        hoverTabs: false,
        showCategory: true,
        months: ['Enero', 'Febrero', 'Marzo', 'Abril'],

        items: [
        {
          id: 1,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Ciencia y Tecnología',
          img: ''
        },
        {
          id: 2,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Tecnología',
          img: ''
        },
        {
          id: 3,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        },
        {
          id: 4,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        }
      ]
      }
    },
    components: {
    },
    mounted(){
      this.send_request();
      this.checkDevice();
    },
    methods:{
      send_request(){
        var vm = this;
        try{
          // vm.$http.get(vm.getServer()+"/v1/outlets/my_favorites?page="+vm.actual_page_favourites, {
          vm.$http.get("app/locations?page="+vm.actual_page, {
            headers:
            this.getUserToken() != '' ?
            {
                "Authorization": "Bearer " + this.getUserToken(),
                "X-Device-ID" : this.buildDeviceId(),
                "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            } :
            {
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            },
            params:{
                company:{id:this.getCurrentCompany()}
            }
          }).then(function(response){
            console.log("----------------> Locations");
            console.log(response);
            for(var key in response.body.data){
              if(response.body.data[key].attributes.location_type != 'Reserva Sublocacion' &&
                 response.body.data[key].attributes.location_type != 'Reserva General'){
                this.venues.push(response.body.data[key])
              }
              if(response.body.data[key].attributes.is_favorite){
                vm.favourites_venues.push(response.body.data[key]);
              }
            }
            // if(this.getUserToken() != '' &&
            //     (this.getLocationUser() == '' ||
            //     this.getLocationUser() == null ||
            //     this.getLocationUser() == undefined)){
            //   this.updateLocationUser(null);
            // }
            this.updateLocations(this.venues);
            if(response.body.data.length > 0){
                vm.actual_page_favourites++;
            }
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            if (response.status==401) {
              // this.show_error_modal(response.body.errors[0].details, "Un momento...");
              this.show_error_modal("Tienes una sesión abierta en otro dispositivo.", "Un momento...");
              this.deleteSession('login', true)
            }else{
              this.show_error_modal(response.body.errors[0].details);
            }
          })
        }catch(e){
            vm.errors_handler(e.message);
        }
      },
    }
  }
</script>
